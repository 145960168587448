import { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
// import RefreshIcon from '../../assets/icons/RefreshIcon.svg';
// import DownloadPDFIcon from '../../assets/icons/DownloadPDFIcon.svg';
// import ManageDashboard from '../../assets/icons/ManageDashboardIcon.svg';
// import EditPageConfigIcon from '../../assets/icons/EditPageConfigIcon.svg';
import ClockSettingsIcon from '../../assets/icons/ClockSettings.svg';
import DPInputSelect from './DPInputSelect';
import classes from './DashboardComponents.module.css';
import { DashboardComponentStyle as styles } from './DashboardComponentStyle';
import SingleDateAndTimePicker from './SingleDateAndTimePicker';
// import AssistantIcon from '@mui/icons-material/Assistant';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { GRANULARITY, TIME_RANGE } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../utilities/helpers';
import dpTheme from '../../styles/theme/dpTheme';
import {
  getPdfForDashboardPage,
  getPdfForUnifiedDashboardPage,
} from '../../services/dashboardPage';
import saveAs from 'file-saver';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { add530Hours } from '../../utils/dashboard.utils';
import { updateTimeFrameForRefId } from '../../services/commonApis';
import { useUnifiedDashboardStore } from '../../store/UnifiedDashboardStore';
import { Settings as SettingsIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditDashboardIcon from '../../core-components/assets/icons/EditDashboardIcon';
import ShareDashboardIcon from '../../core-components/assets/icons/ShareDashboardIcon';

// interface IProps {
//   heading: string;
//   dashboardClusters: DashboardClusterInterface[];
//   openDateAndTimeDialog: Function;
//   refreshSelectedPageData: Function;
//   handleDownLoadPdfButtonClicked: Function;
//   plantId: string | undefined;
//   handlePageMenuChange: Function;
//   dashboardPageData: any;
//   setTimeFrame: any;
// }

const DashboardToolbar = memo(
  ({
    heading,
    dashboardClusters,
    openDateAndTimeDialog,
    refreshSelectedPageData,
    plantId,
    handlePageMenuChange,
    setTimeFrame,
    selectedGranularity,
    setSelectedGranularity,
    selectedRelativeTimeRange,
    setSelectedTimeRange,
    timeFrame,
    selectedDashboardPage,
    setSelectedDashboardPage,
    setIsBottomBarOpen,
    dashboardDateAndTime,
    dashboardSaveData,
    setDashboardSaveData,
    setParent,
    sharedForViewOnly,
    newDashboardPage,
    setNewDashboardPage,
    setOpenAiSearchBar,
    navigationLinks,
    mode,
    pageType = 'ALL',
    canManageDashboard,
    canSharePage = false,
    openShareDialog,
    canEditPage,
    canDownloadAndRefreshReport,
    loadingManageDashboard,
    loadingEditPage,
    loadingDownloadAndRefreshReport,
  }: any) => {
    // const isMobile = useIsMobile();
    const { setPageMode } = useUnifiedDashboardStore();
    const startDate = new Date(new Date().valueOf() - 24 * 60 * 60 * 1000);
    const endDate = new Date();
    const startTime = formatTime(new Date(new Date().valueOf() - 24 * 60 * 60 * 1000));
    const endTime = formatTime(new Date());
    const [loadingForPdf, setLoadingForPdf] = useState(false);
    const [popOverAnchorEl, setPopOverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openCalendarFrom, setOpenCalendarFrom] = useState(false);
    const [openCalendarTo, setOpenCalendarTo] = useState(false);
    const [openTimeFrom, setOpenTimeFrom] = useState(false);
    const [openTimeTo, setOpenTimeTo] = useState(false);
    const [granularityItems, setGranularityItems] = useState(GRANULARITY);
    const updateGranularityItems = (startTimestamp: any, endTimestamp: any) => {
      const timeDifferenceHours = (endTimestamp - startTimestamp) / (60 * 60 * 1000); // Calculate time difference in hours

      let newGranularityItems = GRANULARITY;

      if (timeDifferenceHours <= 6) {
        // Include "Minutes" if the difference is less than or equal to 6 hours
        newGranularityItems = [
          { label: 'Minutes', value: 'minutes' },
          { label: 'Hours', value: 'hours' },
          { label: 'Days', value: 'days' },
        ];
      } else {
        // Calculate date difference in days
        const dateDiff = Math.ceil((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));

        if (dateDiff <= 7) {
          newGranularityItems = [
            { label: 'Hours', value: 'hours' },
            { label: 'Days', value: 'days' },
          ];
        } else if (dateDiff > 7 && dateDiff <= 60) {
          newGranularityItems = [
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
          ];
        } else if (dateDiff > 60) {
          newGranularityItems = [
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months', value: 'months' },
          ];
        }
      }

      const isSelectedGranularityPresent = newGranularityItems.filter(
        obj => obj.label == selectedGranularity
      );

      if (isSelectedGranularityPresent.length !== 1) {
        setSelectedGranularity(newGranularityItems[0].label);
        setGranularityItems(newGranularityItems);

        return {
          granularity: newGranularityItems[0].value.toLowerCase(),
          granularityLabel: newGranularityItems[0].label,
        };
      }

      setGranularityItems(newGranularityItems);

      return {
        granularity: selectedGranularity.toLowerCase(),
        granularityLabel: selectedGranularity,
      };
    };

    useEffect(() => {
      console.log('dashboardSaveData2 : ', dashboardSaveData);
      if (timeFrame !== null) {
        updateGranularityItems(timeFrame?.startDate, timeFrame?.endDate);
      }
    }, [timeFrame]);

    const handleDownLoadPdfButtonClicked = (event: any) => {
      setPopOverAnchorEl(event.currentTarget);
    };

    const pageSelection = useCallback(
      (selectedPage: any) => {
        setSelectedDashboardPage(selectedPage);
        handlePageMenuChange(selectedPage);
      },
      [selectedDashboardPage]
    );

    const granularitySelection = (selectedGranularity: any) => {
      setSelectedGranularity(selectedGranularity.label);
      const timeFrame2 = {
        ...timeFrame,
        granularity: selectedGranularity.value.toLowerCase(),
        granularityLabel: selectedGranularity.label,
      };
      setTimeFrame(timeFrame2);
      const updatedDashboard = {
        ...dashboardSaveData,
        selectedGranularity: {
          unit: selectedGranularity.value.toLowerCase(),
          unitMultiplier: 1,
        },
      };
      setDashboardSaveData(updatedDashboard);
      setNewDashboardPage({
        ...newDashboardPage,
        changes: {
          ...newDashboardPage.changes,
          dashboard: updatedDashboard,
        },
      });
      setParent('dashboard');
      setIsBottomBarOpen(true);
    };

    const timeRangeSelection = useCallback(
      (selectedTimeRange: any) => {
        setSelectedTimeRange(selectedTimeRange.label);
        setParent('dashboard');
        setIsBottomBarOpen(true);
        if (selectedTimeRange.label !== 'Custom') {
          const dateRange = selectedTimeRange.getDateRange();
          const startTimestamp = dateRange[0];
          const endTimestamp = dateRange[1];
          let selectedGranularity: any = updateGranularityItems(startTimestamp.$d, endTimestamp.$d);
          const timeFrame2 = {
            ...timeFrame,
            startDate: startTimestamp.$d,
            endDate: endTimestamp.$d,
            ...selectedGranularity,
          };

          const updatedDashboard = {
            ...dashboardSaveData,
            selectedTimeRange: {
              unit: selectedTimeRange.unit,
              unitMultiplier: selectedTimeRange.value,
              timeRangeType: 'relative',
              startTime: timeFrame2.startDate,
              endTime: timeFrame2.endDate,
            },
            selectedGranularity: {
              unit: selectedGranularity?.granularity,
              unitMultiplier: 1,
            },
          };

          setTimeFrame(timeFrame2);
          setDashboardSaveData(updatedDashboard);
          const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
            timeFrame2.startDate,
            timeFrame2.endDate
          );
          updateTimeFrameForRefId(
            startDate530Ahead,
            endDate530Ahead,
            updatedDashboard.dashboardPageId,
            'dashboardPage'
          ).then(() => {});
        }
      },
      [selectedRelativeTimeRange]
    );

    const saveFile = async (base64str: string) => {
      let binary = atob(base64str.replace(/\s/g, ''));
      let len = binary.length;
      let buffer = new ArrayBuffer(len);
      let view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: 'application/pdf' });
      saveAs(blob, 'plantAnalytics.pdf');
    };

    const downloadPdf = async (printFormat: string) => {
      setPopOverAnchorEl(null);

      setLoadingForPdf(true);
      if (mode === 'unifiedDashboard') {
        await getPdfForUnifiedDashboardPage(
          selectedDashboardPage?.pageId || null,
          timeFrame,
          printFormat
        )
          .then(async data => {
            let base64str = data;

            await saveFile(base64str);
            setLoadingForPdf(false);
          })
          .catch(err => {
            setLoadingForPdf(false);
          });
      } else {
        await getPdfForDashboardPage(
          plantId!,
          selectedDashboardPage?.pageId || null,
          timeFrame,
          printFormat
        )
          .then(async data => {
            let base64str = data;

            await saveFile(base64str);
            setLoadingForPdf(false);
          })
          .catch(err => {
            setLoadingForPdf(false);
          });
      }
    };

    const navigate = useNavigate();

    return (
      <ThemeProvider theme={dpTheme}>
        <Box sx={{ margin: '0px', padding: '0px' }}>
          <Stack direction="column" gap={{ xs: 1, sm: 3 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              justifyContent="space-between"
              gap={2}
            >
              <Typography sx={styles.headingStyle}>{heading}</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
                spacing={{ xs: 2, sm: 3 }}
                sx={{ marginLeft: 'auto' }}
                width={{ xs: '95vw', sm: 'auto' }}
              >
                {pageType != 'ALL_GRID' && (
                  <Tooltip title={'AI Widget'}>
                    <Button
                      sx={styles.ToolBarAIButtonStyle}
                      // className={classes.AIButton}
                      onClick={() => {
                        setOpenAiSearchBar(true);
                      }}
                    >
                      <AutoAwesomeIcon fontSize="medium" sx={{ color: '#451AB2' }} />
                    </Button>
                  </Tooltip>
                )}
                {canDownloadAndRefreshReport && (
                  <>
                    <Tooltip title="Refresh Page">
                      <Button
                        sx={styles.ToolBarButtonStyle}
                        onClick={() => {
                          refreshSelectedPageData();
                        }}
                      >
                        <RefreshIcon fontSize="medium" sx={{ color: '#183650' }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Download">
                      <Button
                        sx={styles.ToolBarButtonStyle}
                        onClick={e => {
                          handleDownLoadPdfButtonClicked(e);
                        }}
                      >
                        <DownloadIcon fontSize="medium" sx={{ color: '#183650' }} />

                        {loadingForPdf ? (
                          <CircularProgress
                            style={{ marginLeft: '5px', color: 'black' }}
                            size={20}
                          />
                        ) : null}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Popover
                  open={Boolean(popOverAnchorEl)}
                  anchorEl={popOverAnchorEl}
                  onClose={() => {
                    setPopOverAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Button
                      onClick={() => {
                        downloadPdf('fullPage');
                      }}
                    >
                      Single Page Format
                    </Button>
                    <hr></hr>
                    <Button
                      onClick={() => {
                        downloadPdf('a4');
                      }}
                    >
                      A4 Page Format
                    </Button>
                  </Box>
                </Popover>
                {canManageDashboard && (
                  <Tooltip title="Manage Reports/Dashboard">
                    <Button
                      sx={styles.ToolBarButtonStyle}
                      onClick={() => {
                        navigate(navigationLinks.dashboardPageList);
                      }}
                    >
                      <SettingsIcon fontSize="medium" sx={{ color: '#183650' }} />
                    </Button>
                  </Tooltip>
                )}
                {canEditPage && (
                  <Tooltip title="Edit Page">
                    <Button
                      sx={styles.ToolBarButtonStyle}
                      onClick={() => {
                        if (pageType == 'ALL_GRID') setPageMode('settings');
                        else navigate(navigationLinks.pageConfig);
                      }}
                      disabled={sharedForViewOnly}
                    >
                      <EditDashboardIcon width={22} height={22} fillColor={'#183650'} />
                    </Button>
                  </Tooltip>
                )}

                {canSharePage && (
                  <Tooltip title="Share Dashboard Page">
                    <Button
                      sx={styles.ToolBarButtonStyle}
                      onClick={() => {
                        openShareDialog(); // Call the function to open the dialog
                      }}
                    >
                      <ShareDashboardIcon width={22} height={22} fillColor={'#183650'} />
                    </Button>
                  </Tooltip>
                )}
                {!useIsMobile({ breakpointValue: 'sm' }) &&
                  useIsMobile({ breakpointValue: 'md' }) && (
                    <DPInputSelect
                      labelId="PageSelectionLabel"
                      id="PageSelection"
                      label="Page"
                      inputSelectOnChange={pageSelection}
                      labelClassName={classes.DPInputSelectLabel}
                      className={classes.DPInputSelectContainer}
                      size={2}
                      menuItems={dashboardClusters}
                      selectedOption={selectedDashboardPage?.label}
                      defaultSelectedOption="xyz"
                      selectedId={selectedDashboardPage?.pageId}
                      setIsBottomBarOpen={setIsBottomBarOpen}
                      sharedForViewOnly={sharedForViewOnly}
                      styles={{ minWidth: { md: '100px', lg: '250px' } }}
                    />
                  )}
              </Stack>
            </Stack>
            <Stack sx={styles.ToolbarSection2}>
              {(useIsMobile({ breakpointValue: 'sm' }) ||
                !useIsMobile({ breakpointValue: 'md' })) && (
                <Box className={classes.DPBoxHighlight}>
                  <DPInputSelect
                    labelId="PageSelectionLabel"
                    id="PageSelection"
                    label="Page"
                    inputSelectOnChange={pageSelection}
                    // labelClassName={classes.DPInputSelectLabel}
                    className={classes.DPInputSelectContainer}
                    size={2}
                    menuItems={dashboardClusters}
                    selectedOption={selectedDashboardPage?.label}
                    defaultSelectedOption="xyz"
                    selectedId={selectedDashboardPage?.pageId}
                    setIsBottomBarOpen={setIsBottomBarOpen}
                    sharedForViewOnly={sharedForViewOnly}
                    styles={{ minWidth: { md: '100px', lg: '250px' } }}
                  />
                </Box>
              )}
              <Stack direction="row" className={classes.DPBoxHighlight} spacing={2}>
                <DPInputSelect
                  labelId="Granularity"
                  id="GranularitySelection"
                  label="Granularity"
                  inputSelectOnChange={granularitySelection}
                  className={classes.DPInputSelectContainer}
                  size={1}
                  menuItems={granularityItems}
                  selectedOption={selectedGranularity}
                  setIsBottomBarOpen={setIsBottomBarOpen}
                  sharedForViewOnly={sharedForViewOnly}
                />
                <DPInputSelect
                  labelId="DaySelectionLabel"
                  id="DaySelection"
                  label=""
                  inputSelectOnChange={timeRangeSelection}
                  className={classes.DPInputSelectContainer}
                  size={1}
                  menuItems={TIME_RANGE.filter(timeRange => timeRange.unit == 'days')}
                  selectedOption={selectedRelativeTimeRange}
                  setIsBottomBarOpen={setIsBottomBarOpen}
                  sharedForViewOnly={sharedForViewOnly}
                />
                <Stack direction="row" spacing={2} sx={{ height: '100%' }}>
                  {!useIsMobile({ breakpointValue: 'sm' }) && (
                    <>
                      <SingleDateAndTimePicker
                        label="From"
                        date={
                          timeFrame && timeFrame.startDate
                            ? formatDate(timeFrame.startDate)
                            : formatDate(startDate)
                        }
                        time={
                          timeFrame && timeFrame.startDate
                            ? formatTime(timeFrame.startDate)
                            : startTime
                        }
                        openCalendar={openCalendarFrom}
                        setOpenCalendar={setOpenCalendarFrom}
                        openTime={openTimeFrom}
                        setOpenTime={setOpenTimeFrom}
                        calendarParent={'from'}
                        timeFrame={timeFrame}
                        setTimeFrame={setTimeFrame}
                        setIsBottomBarOpen={setIsBottomBarOpen}
                        sharedForViewOnly={sharedForViewOnly}
                        dashboardDateAndTime={dashboardDateAndTime}
                        dashboardSaveData={dashboardSaveData}
                        setDashboardSaveData={setDashboardSaveData}
                        setParent={setParent}
                        newDashboardPage={newDashboardPage}
                        setNewDashboardPage={setNewDashboardPage}
                        setSelectedTimeRange={setSelectedTimeRange}
                        updateGranularityItems={updateGranularityItems}
                      />
                      <SingleDateAndTimePicker
                        label="To"
                        date={
                          timeFrame && timeFrame.endDate
                            ? formatDate(timeFrame.endDate)
                            : formatDate(endDate)
                        }
                        time={
                          timeFrame && timeFrame.endDate ? formatTime(timeFrame.endDate) : endTime
                        }
                        openCalendar={openCalendarTo}
                        setOpenCalendar={setOpenCalendarTo}
                        openTime={openTimeTo}
                        setOpenTime={setOpenTimeTo}
                        calendarParent={'to'}
                        timeFrame={timeFrame}
                        setTimeFrame={setTimeFrame}
                        setIsBottomBarOpen={setIsBottomBarOpen}
                        sharedForViewOnly={sharedForViewOnly}
                        dashboardDateAndTime={dashboardDateAndTime}
                        dashboardSaveData={dashboardSaveData}
                        setDashboardSaveData={setDashboardSaveData}
                        setParent={setParent}
                        newDashboardPage={newDashboardPage}
                        setNewDashboardPage={setNewDashboardPage}
                        setSelectedTimeRange={setSelectedTimeRange}
                        updateGranularityItems={updateGranularityItems}
                      />
                    </>
                  )}
                </Stack>
                <Box
                  sx={styles.clockSettingStyle}
                  onClick={() => {
                    openDateAndTimeDialog();
                  }}
                >
                  <img src={ClockSettingsIcon} alt="Clock Settings" />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </ThemeProvider>
    );
  }
);

export default DashboardToolbar;
